import { useEffect, useState } from 'react'
import * as PIXI from 'pixi.js'
import type { Application } from "pixi.js";
import { Live2DModel } from 'pixi-live2d-display';
import { voice } from '../utils/voiceVox';
import ReactGA from "react-ga4";

function About() {
  const [audioData, setAudioData] = useState<Blob>()

  useEffect(() => {
    if (!window) {
      return;
    }
    (window as any).PIXI = PIXI;

    const canvas = document.getElementById('canvas');
    if (!canvas) {
      return;
    }

    const app = new PIXI.Application({
      view: canvas as HTMLCanvasElement,
      autoStart: true,
      width: window.innerWidth,
      height: window.innerHeight,
    });


    Live2DModel.from('/Resources/Hiyori/Hiyori.model3.json').then((model) => {
      const current = app as Application;
      if (!current) {
        return;
      }

      // @ts-ignore
      app.stage.addChild(model);

      // transforms
      model.x = 500;
      model.y = 500;
      model.rotation = Math.PI;
      model.skew.x = Math.PI;
      model.scale.set(0.25, 0.25);
      model.anchor.set(0.5, 0.5);

      // interaction
      model.on('hit', (hitAreas) => {
        if (hitAreas.includes('body')) {
          model.motion('tap_body');
        }
      });
    });

    ReactGA.send({
      hitType: "pageview",
      page: 'about'
    });
  }, [])

  const onSubmit = async () => {
    const res = await voice('テストだよ')
    if (!res) return
    setAudioData(res as Blob)
  };

  return (
    <>
      <div>about</div>
      <button onClick={() => {
        onSubmit();
      }}>test</button>
      <canvas id="canvas" />

      {audioData ? (
        <div>
          <audio
            controls
            src={audioData ? window.URL.createObjectURL(audioData) : undefined}
            autoPlay
            style={{ width: "0" }}
          >
            Your browser does not support
          </audio>
        </div>
      ) : null}
    </>
  )
}

export default About

import styled from "styled-components";

function Footer() {

  return (
    <FooterText>Copyright soneri</FooterText>
  )
}

export default Footer
const FooterText = styled.div`
width: 100%;
height: 30px;
background-color: #224272;
display: flex;
color: white;
align-items: center;
justify-content: center;
`


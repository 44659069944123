import Header from "../components/Header";
import styled from "styled-components";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function ServiceTerm() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: 'service-term',
      title: 'service-term',
    });
  }, [])
  return <div>
    <Header />
    <h2>利用規約</h2>
    <Wrap>
      <div>
        Soneri(ソネリ)(以下，「本サービス」といいます。)は個人の趣味の範囲で運営しているものです。<br />
        本サービスを使用したことによって、何らかの不利益が生じた場合などについては一切の責任を負いかねます。<br />
        また、一人でやっているためお問い合わせなどにすべて返信をすることは難しいです。予めご了承ください。<br />
        以上
      </div>

      <div>禁止事項</div>
      <div>
        法令または公序良俗に違反する行為<br />
        犯罪行為に関連する行為<br />
        本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為<br />
        当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為<br />
        本サービスによって得られた情報を商業的に利用する行為<br />
        当社のサービスの運営を妨害するおそれのある行為<br />
        不正アクセスをし，またはこれを試みる行為<br />
        他のユーザーに関する個人情報等を収集または蓄積する行為<br />
        不正な目的を持って本サービスを利用する行為<br />
        本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為<br />
        他のユーザーに成りすます行為<br />
        当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為<br />
        面識のない異性との出会いを目的とした行為<br />
        当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為<br />
        その他，当社が不適切と判断する行為<br />
      </div>

      <div>本サービスの提供の停止等</div>
      <div>
        ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします<br />
        本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします
      </div>
    </Wrap>
  </div>
}

export default ServiceTerm;
const Wrap = styled.div`
text-align: left;
`

// import { useState } from "react";
import * as PIXI from "pixi.js";
import { Live2DModel } from "pixi-live2d-display";
// import { Application } from "pixi.js";
import { useAtom } from "jotai";
import { modelAtom } from "../states/atoms";

export const useLive2dModel = () => {
  // const [audioData, setAudioData] = useState<Blob>();
  const [model, setModel] = useAtom(modelAtom);

  const init = async () => {
    if (!window) {
      return;
    }
    (window as any).PIXI = PIXI;

    const canvas = document.getElementById('canvas');
    if (!canvas) {
      return;
    }

    const innerWidth = window.visualViewport
      ? window.visualViewport.width
      : window.innerWidth;
    const innerHeight = window.visualViewport
      ? window.visualViewport.height
      : window.innerHeight;

    const app = new PIXI.Application({
      view: canvas as HTMLCanvasElement,
      autoStart: true,
      width: innerWidth,
      height: innerHeight / 2.5,
      backgroundColor: 0xDAE8F4,
    });

    // 背景画像の設定
    const backgroundImagePath = '/background.webp';
    const backgroundTexture = PIXI.Texture.from(backgroundImagePath);
    const backgroundSprite = new PIXI.Sprite(backgroundTexture);
    backgroundSprite.width = innerWidth;
    backgroundSprite.height = innerHeight / 2.5;
    app.stage.addChild(backgroundSprite);

    // const model = await Live2DModel.from('/Resources/Mark/Mark.model3.json');
    const model = await Live2DModel.from('/Resources/BlackCat/BlackCat.model3.json');
    // @ts-ignore
    app.stage.addChild(model);
    // transforms
    model.x = innerWidth / 2;
    model.y = innerHeight / 4;
    model.rotation = Math.PI;
    model.skew.x = Math.PI;
    model.scale.set(0.05, 0.05);
    model.anchor.set(0.5, 0.5);

    // interaction
    model.on('hit', (hitAreas) => {
      if (hitAreas.includes('Body')) {
        model.motion('Punch');
      }
    });

    setModel(model);
  }

  const singStart = async () => {
    if (!model) {
      return;
    }
    await model.motion('Sing', 0);
  };

  const singStop = async () => {
    if (!model) {
      return;
    }
    await model.motion('Ote', 0);
  };

  // const lipSync = async (src: string) => {
  //   if (!model || !app) {
  //     return;
  //   }
  //   const audio = new Audio();
  //   audio.addEventListener("canplaythrough", async function (e) {
  //     const category_name = "Idle";
  //     const animation_index = 0;
  //     const priority = MotionPriority.FORCE;
  //
  //     const result = await model.motion(
  //       'Idle',
  //       0,
  //       MotionPriority.FORCE,
  //       src
  //     );
  //
  //     if (result) {
  //       console.log("リップシンクが正常に開始されました");
  //     } else {
  //       console.log("リップシンクの開始に失敗しました");
  //     }
  //   });
  //   audio.src = src;
  //   audio.load();
  // };

  return{
    init,
    singStart,
    singStop,
  }
}

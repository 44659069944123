import React, { useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import ReactGA from "react-ga4";
import Index from './routes/index';
import ConfessionRoom from './routes/confessionRoom';
import NotFound from './routes/notFound';
import ServiceTerm from "./routes/serviceTerm";
import About from "./routes/about";

export const color = {
  background: {
    primary: "#f6f6f6",
    secondary: "#d6e4f0",
    tertiary: "#1e56a0",
    quaternary: "#163172",
  },
  text: {
    primary: "#212121",
    secondary: "#757575",
    tertiary: "#bdbdbd",
    quaternary: "#000000",
  }
}

function App() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize("G-H7Z7NKSB0L");
    setInitialized(true);
  }, [])

  return (
    initialized ? <div className="App">
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/confession-room" element={<ConfessionRoom />} />
        <Route path="/service-term" element={<ServiceTerm />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div> : <div />
  );
}

export default App;

import { useState } from 'react'
import styled from "styled-components";
import bars from "../assets/images/hamburger.svg";
import seep from "../assets/images/seep.svg";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const onClick= async () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {
        isOpen ? (
          <ModalWrap onClick={() => onClick()}>
            <Modal>
              <Link href="/confession-room"><ModalContent>懺悔室</ModalContent></Link>
              <Link href="https://forms.gle/vhiNUDTC8x1WtDcS9"><ModalContent>お問い合わせ</ModalContent></Link>
              <Link href="/service-term"><ModalContent>利用規約</ModalContent></Link>
              <ModalContentLast onClick={() => onClick()}>閉じる</ModalContentLast>
            </Modal>
          </ModalWrap>
        ) : null
      }
      <HeaderWrapper>
        <Link href={"/"}>
          <TitleWrap>
            <Title>Soneri(β)</Title>
            <Logo src={seep} alt="logo" />
          </TitleWrap>
        </Link>
        <Hamburger src={bars} alt="humberger" onClick={() => onClick()} />
      </HeaderWrapper>
    </div>
  )
}

export default Header
const Link = styled.a`
text-decoration: none;
color:inherit
`

const ModalWrap = styled.div`
width: 100%;
height: 100%;
position: fixed;
background-color: rgba(0, 0, 0, 0.2);
z-index: 100;
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
color: #707070;
font-size: 14px;
border-radius: 2px;
`

const Modal = styled.div`
background: white;
position: fixed;
width: 60%;
top: 30px;
right: 15px;
border-radius: 10px;
`

const ModalContent = styled.div`
font-size: 18px;
text-align: left;
font-weight: bold;
padding: 16px;
border-bottom: solid;
`

const ModalContentLast = styled.div`
font-size: 18px;
text-align: left;
font-weight: bold;
padding: 16px;
`

const TitleWrap = styled.div`
display: flex;
align-items: center;
`

const Title = styled.span`
font-size: 32px;
font-weight: bold;
color: white;
margin-right: 15px;
`

const HeaderWrapper = styled.div`
width: 100%;
height: 60px;
background-color: #224272;
display: flex;
align-items: center;
justify-content: center;
`

const Hamburger = styled.img`
width: 30px;
height: 30px;
margin-right: 15px;
position: absolute;
right: 0;
`

const Logo = styled.img`
width: 30px;
height: 30px;
`


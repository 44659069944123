import Header from "../components/Header";
import styled from "styled-components";
import blackCat from "../assets/images/blackcat.png";
import background from "../assets/images/background.webp";
import live2dLogo from "../assets/images/live2d_logo.png";
import cubismLogo from "../assets/images/cubism_logo.png";
import Footer from "../components/Footer";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { awakeServer } from "../utils/chatGpt";

function Index() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: 'top',
      title: 'top',
    });
    awakeServer();
  }, [])
  return (
    <div>
      <Header />
      <FirstView>
        <Background src={background} alt="logo" />
        <BlackCat src={blackCat} alt="logo" />
        <DescriptionWrap>
          <TitleWrap>
            <SubTitle>猫とともに歩む、悩みからの解放へ</SubTitle>
            <Title>Soneri</Title>
          </TitleWrap>
          <StartButton><Link href="/confession-room">懺悔室へ入る</Link></StartButton>
        </DescriptionWrap>
      </FirstView>

      <ContentWrap>
        <ContentTitle>Soneri(ソネリ)とは？</ContentTitle>
        <ContentText>
          匿名で悩みを共有し、癒しと助言を得るサービスです。懺悔室にてチャット形式で悩みの相談ができます。
        </ContentText>
      </ContentWrap>

      <ContentWrap>
        <ContentTitle>Q&A</ContentTitle>
        <ContentText>
          <QuestionText>
            <QuestionMark>Q</QuestionMark>: どのように匿名性が保たれますか？
          </QuestionText>
          <AnswerText>
            <AnswerMark>A</AnswerMark>: 当サイトではユーザーの個人情報を一切収集せず、匿名の投稿が可能です。信頼性を重視しています。
          </AnswerText>
        </ContentText>

        <ContentText>
          <QuestionText>
            <QuestionMark>Q</QuestionMark>: 懺悔室の利用は無料ですか？
          </QuestionText>
          <AnswerText>
            <AnswerMark>A</AnswerMark>: はい、懺悔室は完全に無料でご利用いただけます。感情の共有と癒しを支援します。
          </AnswerText>
        </ContentText>

        <ContentText>
          <QuestionText>
            <QuestionMark>Q</QuestionMark>: 見た目がおかしい or 不具合がありました
          </QuestionText>
          <AnswerText>
            <AnswerMark>A</AnswerMark>: お手数ですが、<a href="https://forms.gle/vhiNUDTC8x1WtDcS9">お問い合わせ</a>よりご連絡ください。可能な限り対応いたします。
          </AnswerText>
        </ContentText>
      </ContentWrap>

      <ContentWrap>
        <LogoWrap>
          <Logo src={live2dLogo} alt="logo_live2d" />
          <Logo src={cubismLogo} alt="logo_cubism" />
        </LogoWrap>
        <div>Powered by Live2D</div>
        <div>利用：VOICEVOX:ナースロボ＿タイプＴ</div>
      </ContentWrap>


      <Footer />
    </div>
  );
}

export default Index;
const FirstView = styled.div`
position: relative;
`

const Background = styled.img`
width: 100%;
opacity: 0.7;
`

const BlackCat = styled.img`
width: 40%;
top: 5%;
right: 5%;
position: absolute;
`
const DescriptionWrap = styled.div`
position: absolute;
top: 25%;
left: 5%;
width: 60%;
`

const TitleWrap = styled.div`
padding: 10px;
border-radius: 10px;
background: rgba(255, 255, 255, 0.5);
`

const Title = styled.div`
font-size: 36px;
font-weight: bold;
`

const SubTitle = styled.div`
font-size: 12px;
font-weight: bold;
`

const StartButton = styled.div`
font-size: 12px;
font-weight: bold;
margin-top: 30px;
background: rgba(225, 48, 9, 0.95);
border-radius: 10px;
padding: 10px;
width: 70%;
color: white;
`

const Link = styled.a`
text-decoration: none;
color:inherit
`

const ContentWrap = styled.div`
margin: 10px 0;
padding: 20px;
`

const ContentTitle = styled.div`
font-size: 24px;
`

const ContentText = styled.div`
font-size: 18px;
padding: 20px;
text-align: left;
`

const QuestionText = styled.div`
margin: 10px 0;
font-weight: bold;
`

const AnswerText = styled.div`
margin: 10px 0;
`

const QuestionMark = styled.span`
color: blue;
`

const AnswerMark = styled.span`
color: red;
`
const LogoWrap = styled.div`
display: flex;
justify-content: center;
`

const Logo = styled.img`
width: 30%;
margin: 0 10px;
`

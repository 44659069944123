import {useEffect, useRef, useState} from 'react'
import { voice } from "../utils/voiceVox";
import { Icon } from '@iconify/react';
import styled from "styled-components";
import { postChat } from "../utils/chatGpt";
import { Howl } from 'howler'
import loading from "../assets/images/loading.gif";
import { useLive2dModel } from "../hooks/useLive2dModel";

function MessageWindow() {
  const [content, setContent] = useState<any[]>([])
  // const [changeColor, setChangeColor] = useState("#e0e0e0")
  const [phrase, setPhrase] = useState("")
  const [isThinking, setIsThinking] = useState(false)
  const footRef = useRef(null)
  const [chatHeight, setChatHeight] = useState(300)
  const { singStart } = useLive2dModel();

  useEffect(() => {
    async function fetchData() {
      try {
        const selfIntroductionVoice = await fetch('/selfIntroduction.txt');
        const text = await selfIntroductionVoice.text();
        const sound = new Howl({
          src: text,
          autoplay: true,
          loop: false,
        })
        sound.play()

        // todo: 音声ファイルを保存してlipSyncで実行したい
        let counter = 0;
        const timerId = setInterval(function(){
          singStart();
          if(++counter > 3){
            clearInterval(timerId)
          }
        }, 1000)
      } catch (error) {
        console.error('データの取得エラー:', error);
      }
    }
    setContent([
      ...content,
      {
        role: "assistant",
        content: "やっほー、シスターのレヴィだよ。今日はどうしたの？おしゃべりでスッキリしちゃうこともあるし話してみて！",
      }
    ])
    fetchData();

    const innerHeight = window.visualViewport
      ? window.visualViewport.height
      : window.innerHeight;

    setChatHeight(window.innerHeight - innerHeight / 2.5 - 130)

    // eslint-disable-next-line
  }, []);

  // 文言をtxtに保存して再生する時に使う
  // const onTest = async () => {
  //   const res = await voice('やっほー、シスターのレヴィだよ。今日はどうしたの？おしゃべりでスッキリしちゃうこともあるし話してみて！')
  //   let reader = new FileReader()
  //   reader.readAsDataURL(res as Blob)
  //   reader.onload = () => {
  //     console.log(reader.result as string)
  //     const sound = new Howl({
  //       src: reader.result as string,
  //       autoplay: true,
  //       loop: false,
  //     })
  //     sound.play();
  //   }
  // }

  const onSubmit = async () => {
    let tmpContent = content
    setContent([
      ...content,
      {
        role: "user",
        content: phrase,
      }
      ])

    tmpContent.push({
      role: "user",
      content: phrase,
    })

    setPhrase('');

    setIsThinking(true);

    // ChatGPTに接続
    const response = await postChat(tmpContent.slice(1))

    // 句読点で区切る
    let responseSentence = response.response
    if (responseSentence && responseSentence.trim().endsWith('。')) {
      responseSentence = responseSentence.trim().slice(0, -1)
    }

    const tmpSentences = responseSentence.split('。')
    for(const sentence of tmpSentences) {
      // VOICEVOXに接続
      const res = await voice(sentence)

      let reader = new FileReader()
      reader.readAsDataURL(res as Blob)
      reader.onload = () => {
        setIsThinking(false);
        const sound = new Howl({
          src: reader.result as string,
          autoplay: true,
          loop: false,
        })
        sound.play();
        setContent([
          ...tmpContent, {
            role: "assistant",
            content: sentence,
          }
        ])

        tmpContent.push({
          role: "assistant",
          content: sentence,
        })

        // todo: 音声ファイルを保存してlipSyncで実行したい
        let counter = 0;
        const timerId = setInterval(function(){
          singStart();
          if(++counter > 5){
            clearInterval(timerId)
          }
        }, 1000)
      }
    }
  };

  useEffect(() => {
    const scroller = document.getElementById('chat-area');
    if (scroller !== null) {

      scroller.scrollTo({
        top: scroller!.scrollHeight,
        behavior: 'smooth',
      });
    }
  },[content]);

  return (
    <div style={{ position: "relative" }}>
      {
        isThinking ? (
          <div>
            <Loading />
            <LoadingImg src={loading} />
          </div>
        ) :null
      }
      <Display id="chat-area" color={'#e0e0e0'} style={{ height: chatHeight }}>
        {content.map((phraseArray, index) => (
          <div key={index}>
            <Phrase role={phraseArray.role}>
              <Background>
                {phraseArray.content}
              </Background>
            </Phrase>
            {function () {
              if (phraseArray.image) {
                return (
                  <Phrase role={phraseArray.role}>
                    <ImageBackground>
                      <ChatImage src={phraseArray.image} />
                    </ImageBackground>
                  </Phrase>
                )
              }
            }()}

            {index === content.length - 1 && <div ref={footRef}></div>}
          </div>
        ))}
      </Display>

      <InputFormArea>
        <Form>
          <TextInputArea
            id="textarea"
            onChange={(event) => setPhrase(event.target.value)}
            value={phrase}
            disabled={isThinking}
          />
          <SubmitButton
            onClick={() => onSubmit()}
          >
            <Icon icon="fluent:send-16-regular" width="20" height="20" />
          </SubmitButton>
        </Form>
      </InputFormArea>
      <div id="parent" />
    </div>
  )
}

export default MessageWindow

const Display = styled.div`
max-width: 600px;
background-color: ${(props) => props.color};
padding-top:15px;
overflow: auto;
position: relative;
`

const Phrase = styled.div<any>`
width:90%;
text-align:right;
margin :0 auto 10px;
display:flex;
justify-content:${(props) => props.role === "user" ? "flex-end" : "flex-start"};
align-items: center;
`

const Background = styled.div`
background-color:white;
height:100%;
padding:10px 20px;
border-radius:10px;
display:flex;
align-items: center;
text-align:left;
max-width:70%;
overflow-wrap: anywhere;
`

const Loading = styled.div`
width: 100%;
background: rgb(0,0,0,0.3);
height: 100%;
position: absolute;
z-index: 1000;
`

const LoadingImg = styled.img`
position: absolute;
z-index: 1000;
top: 30%;
left: 45%;
`

const ImageBackground = styled.div`
height:100%;
min-height:45px;
padding:10px 0px;
border-radius:10px;
display:flex;
align-items: center;
text-align:left;
max-width:70%;
`

const ChatImage = styled.img`
max-width:90%;
object-fit:cover;
`

const InputFormArea = styled.div`
max-width:600px;
height:50px;
padding-bottom: 10px;
background-color:#e0e0e0;
display:flex;
align-items: center;
justify-content:center;
`

const Form = styled.div`
display:flex;
width:90%;
height:70%;
justify-content: center;
justify-content: space-between;
`

const TextInputArea = styled.textarea`
width: calc(100% - 80px);
border-radius:10px;
border:1px solid #e0e0e0;
background-color:#fbf9ff;
&:focus {
  outline: none;
  border: 2px solid#666666;
}
`

const SubmitButton = styled.button`
margin-left:15px;
width:80px;
border-radius:10px;
border:none;
color:#666666;
padding:2px 0 0 2px;
`

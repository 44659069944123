import axios from "axios";

export async function voice(message: string) {
  const res = await axios.post(
    `https://voicevox-engine-eoinrw7vzq-an.a.run.app/audio_query`,
    null,
    {
      params: {
        speaker: 48,
        text: message,
        speedScale: 1.5,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })

  if (!res) return
  const response = await axios.post<ArrayBuffer>(
    `https://voicevox-engine-eoinrw7vzq-an.a.run.app/synthesis`,
    res.data,
    {
      params: {
        speaker: 48,
        enable_interrogative_upspeak: true,
        speedScale: 1.5,
      },
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  // todo: 音声ファイルを作成 + lipSyncの実装
  // const audioBlob = new Blob([response.data], { type: "audio/x-wav" });
  // const fileName = `${uuidv4()}.wav`;
  // const filePath = '/tmp.wav';
  // fs.writeFileSync(filePath, (Buffer.from(await audioBlob.arrayBuffer())));
  // ファイル名をリターン

  return response.data as unknown as Blob
}

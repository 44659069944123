import {useEffect} from "react";
import ReactGA from "react-ga4";

function NotFound() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: 'not-found',
      title: 'not-found',
    });
  }, [])
  return <h2>このページは存在しません。</h2>;
}

export default NotFound;

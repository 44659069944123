import Live2D from "../components/Live2D";
import MessageWindow from "../components/MessageWindow";
import Header from "../components/Header";
import { useEffect } from "react";
import ReactGA from "react-ga4";


function ConfessionRoom() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: 'confession-room',
      title: 'confession-room',
    });
  }, [])

  return <div>
    <Header />
    <Live2D />
    <MessageWindow />
  </div>;
}

export default ConfessionRoom;

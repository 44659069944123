import { useEffect } from 'react'
import { useLive2dModel } from "../hooks/useLive2dModel";

function Live2D() {
  const { init } = useLive2dModel();
  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <canvas id="canvas" />
    </>
  )
}

export default Live2D

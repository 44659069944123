import axios from "axios";

export function awakeServer() {
  axios.get(
    `https://bael-back-eoinrw7vzq-an.a.run.app/`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((e) => {})

  axios.get(
    `https://voicevox-engine-eoinrw7vzq-an.a.run.app/audio_query`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((e) => {})
}

export async function postChat(messages: any[]) {
  const res = await axios.post(
    `https://bael-back-eoinrw7vzq-an.a.run.app/chat_gpt`,
    {
      messages: messages
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    })

  if (!res) return
  return res.data
}
